<template>
  <v-alert
    :dismissible="!noCloseBtn"
    :color="messageType"
    border="left"
    dark
    :icon="$options.iconMap[messageType]"
  >
    <div class="text-left">
      <div v-html="message"></div>
      <div>
        {{ secondaryMessage }}
      </div>
    </div>
  </v-alert>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: ""
    },
    secondaryMessage: {
      type: String,
      default: ""
    },
    messageType: {
      type: String,
      default: ""
    },
    noCloseBtn: {
      type: Boolean,
      default: false
    }
  },
  iconMap: {
    info: "mdi-message-processing",
    error: "mdi-comment-remove",
    success: "mdi-check"
  }
};
</script>

<style scoped></style>
